import {User} from '@hconnect/apiclient'
import {TFunction} from 'i18next'
import {find} from 'lodash'
import {Dispatch} from 'react'
import {v4 as uuidV4} from 'uuid'

import {trackEvent} from '../../../common/analytics'
import {BusinessLineType, Optional} from '../../../common/types'
import {
  downloadDocument,
  Entity,
  EntityPage,
  getDocumentFileName
} from '../../../Organisms/Documents'
import {MaterialTest} from '../../../Organisms/Materialtest'
import {Delivery, LinkType, LinkMedia, RelatedEntity} from '../../Finance/Invoices/Delivery.types'

export const getTestDocumentLink = (testDocument: MaterialTest) => {
  if ('links' in testDocument && Array.isArray(testDocument.links)) {
    const testPdf = find(
      testDocument.links,
      (link) => link.rel === 'documents' && link.type === 'testResult'
    )
    return testPdf
  }
}
export const getDeliveryDocumentLink = (deliveryDocument: Optional<Delivery>) => {
  if (!deliveryDocument) return
  if ('links' in deliveryDocument && Array.isArray(deliveryDocument.links)) {
    const deliveryPdf = find(
      deliveryDocument.links,
      (link) => link.rel === RelatedEntity.documents && link.type === LinkType.DeliveryNote
    )
    return deliveryPdf
  }
}
export const downloadDeleveryTicket = (
  testDocument: MaterialTest,
  deliveryData: Optional<Delivery>,
  user: User | null,
  startDate: string,
  endDate: string,
  t: TFunction,
  dispatch: Dispatch<any>
) => {
  const {deliveryNumber, customerId, loadedDate} = testDocument
  const deliveryPdf = getDeliveryDocumentLink(deliveryData)
  if (!deliveryPdf) return

  const documentId = deliveryPdf.href ? deliveryPdf.href.split('/')[1] : ''
  const fileName = getDocumentFileName(EntityPage.rmc, Entity.delivery, LinkMedia.Pdf, t, {
    entityNumber: deliveryNumber
  })

  const jobId: string = uuidV4()
  trackEvent('hubDownloadSingle', {
    product: 'hub',
    jobId: jobId,
    downloadedItem: 'deliveryNote',
    linkToDownloadedItem: deliveryPdf.href,
    customerId: customerId,
    entryPoint: 'Material Test List',
    filteredDateRange: {startDate, endDate},
    issuedDate: loadedDate,
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: 'AU',
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    downloadedItemBusinessLine: BusinessLineType.RMC
  })
  dispatch(downloadDocument(jobId, documentId, fileName))
}

export const downloadMaterialTest = (
  testDocument: MaterialTest,
  user: User | null,
  startDate: string,
  endDate: string,
  t: TFunction,
  dispatch: Dispatch<any>
) => {
  const {reportNumber, customerId, loadedDate} = testDocument
  const testPdf = getTestDocumentLink(testDocument)
  if (!testPdf) return

  const documentId = testPdf.href ? testPdf.href.split('/')[1] : ''
  const fileName = getDocumentFileName(EntityPage.rmc, Entity.materialTest, LinkMedia.Pdf, t, {
    entityNumber: reportNumber
  })

  const jobId: string = uuidV4()
  trackEvent('hubDownloadSingle', {
    product: 'hub',
    jobId: jobId,
    downloadedItem: 'materialTest',
    linkToDownloadedItem: testPdf.href,
    customerId: customerId,
    entryPoint: 'Material Test List',
    filteredDateRange: {startDate, endDate},
    issuedDate: loadedDate,
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: 'AU',
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    downloadedItemBusinessLine: BusinessLineType.RMC
  })
  dispatch(downloadDocument(jobId, documentId, fileName))
}
