import {dateFormatter, timeFormatter} from '@hconnect/uikit'
import {Typography} from '@material-ui/core'
import {Box, Card, CardContent, Divider, Grid} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'
import {makeStyles} from 'tss-react/mui'

import {FieldValue} from '../../../../Molecules/FieldDetails'
import {MaterialTest} from '../../../../Organisms/Materialtest'
import {useFetchDelivery} from '../Hooks/fetchDelivery'

import {DeliveryNumber, TestAvailabilityTemplate, TestNumber} from './MaterialTestValues'
import MaterialDescription from '../../../../Molecules/MaterialDescription'

const useStyles = makeStyles()((theme) => ({
  card: {
    width: '100%',
    marginTop: theme.spacing(2),
    backgroundColor: '#f7f7f7'
  }
}))
interface MaterialTestCardProps {
  test: MaterialTest
  id: number
  t: TFunction
  language: string
  startDate: string
  endDate: string
}

const MaterialTestCard: React.FC<MaterialTestCardProps> = ({
  test,
  id,
  t,
  language,
  startDate,
  endDate
}) => {
  const {
    deliveryNumber,
    customerId,
    materialDescription,
    customerMaterialDescription,
    loadedDate,
    sampleTime,
    testId
  } = test
  const {classes} = useStyles()
  const {data: deliveryData} = useFetchDelivery({customerId, deliveryNumber})

  return (
    <Card variant="outlined" className={classes.card} data-test-id={`invoice-delivery-${id}-card`}>
      <CardContent style={{paddingBottom: 16}}>
        <Grid container>
          <Grid item xs={12}>
            <FieldValue
              label={t('materialTest.expand.testType.header')}
              dataTestId={`material-test-${testId}-test-type`}
              value={t(`materialTest.expand.testType.${test?.specimens && test.specimens[0].type}`)}
              gutter
            />
          </Grid>
          <Grid item xs={12}>
            <FieldValue
              label={t('materialTest.expand.scheduledFor')}
              dataTestId={`material-test-${testId}-scheduled-for`}
              value={<TestAvailabilityTemplate test={test} t={t} />}
              gutter
            />
          </Grid>

          <Grid item xs={6}>
            <FieldValue
              label={t('materialTest.expand.sampleTime')}
              dataTestId={`material-test-${testId}-sample-time`}
              value={
                sampleTime && (
                  <Box display="flex" flexDirection="column">
                    {timeFormatter(sampleTime, language)}
                    <Typography color="secondary" variant="subtitle1">
                      {dateFormatter(sampleTime, language)}
                    </Typography>
                  </Box>
                )
              }
              gutter
            />
          </Grid>

          <Grid item xs={6}>
            <FieldValue
              label={t('materialTest.expand.loadedDate')}
              dataTestId={`material-test-${testId}-loaded-date`}
              value={
                loadedDate && (
                  <Box display="flex" flexDirection="column">
                    {timeFormatter(loadedDate, language)}
                    <Typography color="secondary" variant="subtitle1">
                      {dateFormatter(loadedDate, language)}
                    </Typography>
                  </Box>
                )
              }
              gutter
            />
          </Grid>

          <Grid item xs={12}>
            <FieldValue
              label={t('materialTest.expand.material')}
              dataTestId={`material-test-${testId}-material`}
              value={
                <MaterialDescription
                  materialDescription={materialDescription}
                  customerMaterialDescription={customerMaterialDescription}
                />
              }
              gutter
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('materialTest.expand.ticketNumber')}
              dataTestId={`material-test-${testId}-ticket-number`}
              value={
                <DeliveryNumber
                  test={test}
                  startDate={startDate}
                  endDate={endDate}
                  t={t}
                  deliveryData={deliveryData}
                />
              }
              gutter
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('materialTest.expand.testNumber')}
              dataTestId={`material-test-${testId}-test-number`}
              value={<TestNumber test={test} startDate={startDate} endDate={endDate} t={t} />}
              gutter
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

interface MaterialTestListProps {
  tests: MaterialTest[]
  t: TFunction
  language: string
  startDate: string
  endDate: string
}

export const MaterialTestList: React.FC<MaterialTestListProps> = ({
  tests,
  t,
  language,
  startDate,
  endDate
}) => {
  if (!tests || tests.length === 0) return null

  return (
    <>
      <Divider />
      {tests.map((test, i) => (
        <MaterialTestCard
          key={test.testId + i}
          test={test}
          id={i + 1}
          language={language}
          t={t}
          startDate={startDate}
          endDate={endDate}
        />
      ))}
    </>
  )
}
