import {PrimaryCell, Typography, dateFormatter, TableColumnType} from '@hconnect/uikit'
import {TFunction} from 'i18next'
import React from 'react'

import {MaterialTestPreview} from '../../../Organisms/Materialtest'

import {MaterialTestStatus} from './Components/MaterialTestValues'

export const MaterialTestColumns: (t: TFunction, language: string) => TableColumnType[] = (
  t: TFunction,
  language: string
) => [
  {
    field: 'shippingDate',
    headerName: t('materialTest.shippingDate'),
    flex: 1,
    renderCell: (testDocument: MaterialTestPreview) => (
      <PrimaryCell>
        {testDocument.shippingDate ? dateFormatter(testDocument.shippingDate, language) : '-'}
      </PrimaryCell>
    )
  },
  {
    field: 'shipTo',
    headerName: t('materialTest.shipTo'),
    flex: 1,
    renderCell: (testDocument: MaterialTestPreview) => (
      <PrimaryCell>{testDocument?.shippingAddress?.siteName}</PrimaryCell>
    )
  },
  {
    field: 'siteContact',
    headerName: t('materialTest.siteContact'),
    flex: 1,
    renderCell: (testDocument: MaterialTestPreview) => (
      <>
        <PrimaryCell>{testDocument?.contactInformation?.siteContactName}</PrimaryCell>
        <Typography color="secondary" variant="subtitle1">
          {testDocument?.contactInformation?.siteContactNumber}
        </Typography>
      </>
    )
  },
  {
    field: 'orderNumber',
    headerName: t('materialTest.orderNumber'),
    flex: 1,
    renderCell: (testDocument: MaterialTestPreview) => (
      <PrimaryCell>{testDocument?.orderNumber}</PrimaryCell>
    )
  },
  {
    field: 'testReportStatus',
    headerName: t('materialTest.testReportStatus.header'),
    flex: 1,
    renderCell: (testDocument: MaterialTestPreview) => (
      <MaterialTestStatus test={testDocument} t={t} />
    )
  }
]
