import {selectCustomerFilterAbstract} from '../../../Organisms/Customers'
import {createSelectFilter} from '../../../Organisms/Filters'
import {selectMaterialTests} from '../../../Organisms/Materialtest'
import {selectProjectFilterAbstract} from '../../../Organisms/Projects'
import {AppState} from '../../../Root.store'

import {clearFilters, removeFilter, setDefaultFilter, setFilter} from './MaterialTest.action'
import {LookupMaterialTestItems} from './MaterialTest.enum'

/*
Project
  */
export const selectProjectFilter = (state: AppState) =>
  selectProjectFilterAbstract(selectMaterialTests(state))

/*
Site
 */
const SITEFILTER = 'site'
export const setSiteFilter = ({siteId}: {siteId: string}) => setFilter(SITEFILTER, {siteId})
export const setDefaultSiteFilter = ({siteId}: {siteId: string}) =>
  setDefaultFilter(SITEFILTER, {siteId})
export const removeSiteFilter = () => removeFilter(SITEFILTER)
const selectSiteFilterAbstract = createSelectFilter(SITEFILTER)
export const selectSiteFilter = (state: AppState) =>
  selectSiteFilterAbstract(selectMaterialTests(state))

/*
Plants
 */
const PLANTFILTER = 'plant'
export const setPlantFilter = ({plantId}: {plantId: string}) => setFilter(PLANTFILTER, {plantId})
export const setDefaultPlantFilter = ({plantId}: {plantId: string}) =>
  setDefaultFilter(PLANTFILTER, {plantId})
export const removePlantFilter = () => removeFilter(PLANTFILTER)
const selectPlantFilterAbstract = createSelectFilter(PLANTFILTER)
export const selectPlantFilter = (state: AppState) =>
  selectPlantFilterAbstract(selectMaterialTests(state))

/*
Date
 */
const DATEFILTER = 'date'
export const setDateFilter = ({startDate, endDate}) => setFilter(DATEFILTER, {startDate, endDate})
export const setDefaultDateFilter = ({startDate, endDate}) =>
  setDefaultFilter(DATEFILTER, {startDate, endDate})
export const removeDateFilter = () => removeFilter(DATEFILTER)
const selectDateFilterAbstract = createSelectFilter(DATEFILTER)
export const selectDateFilter = (state: AppState) =>
  selectDateFilterAbstract(selectMaterialTests(state))

/*
Lookup
 */
export interface LookupFilterType {
  entity: LookupMaterialTestItems | null
  value: string
  label?: string
}
const LOOKUPFILTER = 'lookup'
export const setLookupFilter = ({entity = null, value, label}: LookupFilterType) =>
  setFilter(LOOKUPFILTER, {
    entity,
    value,
    label
  })
export const removeLookupFilter = () => removeFilter(LOOKUPFILTER)
const selectLookupFilterAbstract = createSelectFilter(LOOKUPFILTER)
export const selectLookupFilter = (state: AppState) =>
  selectLookupFilterAbstract(selectMaterialTests(state))

/*
Customer a.k.a. Accounts
 */
export const selectCustomerOrderFilter = (state: AppState) =>
  selectCustomerFilterAbstract(selectMaterialTests(state))

/*
Reset
 */
export const clearAllFilters = () => (dispatch) => dispatch(clearFilters())
