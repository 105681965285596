import {withRouteRequiringPermission} from '../../Molecules/PermissionBoundary'
import {PermissionTypes} from '../../Permissions'

import {CertificatePage as Certificate} from './Certificate'

export const ROUTE = '/certificates'

export default withRouteRequiringPermission({
  path: ROUTE,
  label: 'Test & Certificates',
  labelKey: 'certificate.navigationLabel',
  order: 99,
  permissions: [PermissionTypes.VIEW_MATERIALCERTIFICATES, PermissionTypes.VIEW_MATERIALTESTS],
  featureToggleName: ['TestReportsMillCertificates', 'RMCTestReports'],
  excludeFeatureToggleName: 'UseHaulierView'
})(Certificate)
