import {dateFormatter} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Address} from '../../../../Molecules/Address'
import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {useFeaturesState} from '../../../../Organisms/Features'
import {MaterialTestPreview} from '../../../../Organisms/Materialtest'
import {useIconStyles} from '../../../Finance/Invoices/InvoiceDetail/components'
import {FieldValue, ValueDiv} from '../../../Order/components'

import {MaterialTestList} from './MaterialTestList'
import {MaterialTestStatus} from './MaterialTestValues'

interface MaterialTestDetailsProps {
  materialTest: MaterialTestPreview
  startDate: string
  endDate: string
  close: () => void
}

export const MaterialTestDetails: React.FC<MaterialTestDetailsProps> = ({
  materialTest,
  startDate,
  endDate,
  close
}) => {
  const {classes} = useIconStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {getFeature} = useFeaturesState()

  return (
    <Box data-test-id="material-test-details">
      <CustomHeader title={t('materialTest.details')}>
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => close()}
          data-test-id="material-test-details-close"
          sx={{px: 2, py: 0, mt: -2}}
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>

      <Grid container>
        <Grid item xs={12}>
          <FieldValue
            label={t('materialTest.shippingDate')}
            value={
              materialTest?.shippingDate ? dateFormatter(materialTest?.shippingDate, language) : '-'
            }
            dataTestId="material-test-shipping-date"
            gutter
          />
        </Grid>
        <Grid item xs={12}>
          <FieldValue label={t('materialTest.shipTo')} dataTestId="material-test-ship-to" gutter>
            {materialTest?.shippingAddress?.siteName}
            {materialTest?.shippingAddress?.street && (
              <Address
                street={materialTest.shippingAddress.street}
                city={materialTest.shippingAddress.city || ''}
                state={materialTest.shippingAddress.state || ''}
                postalCode={materialTest.shippingAddress.postalCode || ''}
                showState={getFeature('ShowState')}
              />
            )}
          </FieldValue>
        </Grid>
        <Grid item xs={12}>
          <FieldValue
            label={t('materialTest.siteContact')}
            dataTestId="material-test-site-contact"
            gutter
          >
            <>
              <ValueDiv>{materialTest?.contactInformation?.siteContactName}</ValueDiv>
              <Typography color="secondary" variant="subtitle1">
                {materialTest?.contactInformation?.siteContactNumber}
              </Typography>
            </>
          </FieldValue>
        </Grid>
        <Grid item xs={6}>
          <FieldValue
            label={t('materialTest.orderNumber')}
            value={materialTest?.orderNumber}
            dataTestId="material-test-order-number"
            gutter
          />
        </Grid>
        <Grid item xs={6}>
          <FieldValue
            label={t('materialTest.testReportStatus.header')}
            dataTestId="material-test-status"
            gutter
          >
            <MaterialTestStatus test={materialTest} t={t} />
          </FieldValue>
        </Grid>
        <Grid item xs={12}>
          <MaterialTestList
            tests={materialTest.items}
            startDate={startDate}
            endDate={endDate}
            t={t}
            language={language}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
