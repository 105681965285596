import {uniq} from 'lodash'
import {combineReducers} from 'redux'

import {
  MATERIALTESTS_CLEAR,
  MATERIALTESTS_FETCH_SUCCESS,
  MaterialTestsActionTypes,
  MaterialTestsStateType,
  materialTests
} from '../../../Organisms/Materialtest'

import {
  MATERIALTESTS_CLEAR_VIEW,
  MATERIALTESTS_SWITCH_VIEW,
  MaterialTestPageActionTypes,
  MaterialTestPageState
} from './Action.types'
import {MaterialTestView} from './MaterialTest.enum'

const initialState: MaterialTestPageState = {
  currentView: MaterialTestView.DEFAULT,
  [MaterialTestView.DEFAULT]: [],
  [MaterialTestView.LOOKUP]: []
}

export const view = (
  state = initialState,
  action: MaterialTestPageActionTypes | MaterialTestsActionTypes
) => {
  switch (action.type) {
    case MATERIALTESTS_SWITCH_VIEW:
      return {
        ...state,
        [state.currentView]: action.clearPrevView ? [] : state[state.currentView],
        currentView: MaterialTestView[action.view] || MaterialTestView.DEFAULT
      }

    case MATERIALTESTS_CLEAR_VIEW:
      return {
        ...state,
        [state.currentView]: []
      }

    case MATERIALTESTS_FETCH_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const collectionIds = action.payload.map((item) => item.orderNumber)
      return {
        ...state,
        [state.currentView]: uniq(collectionIds)
      }

    case MATERIALTESTS_CLEAR:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export interface MaterialTestState {
  materialTests: MaterialTestsStateType
  view: MaterialTestPageState
}
export default combineReducers<MaterialTestState>({
  materialTests,
  view
})
