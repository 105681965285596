import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {selectCustomers} from '../../../../Organisms/Customers'
import {Customer} from '../../../../Organisms/Customers/Customers.types'
import {Site} from '../../../../Organisms/Sites/Sites.types'
import SitesDropdown from '../../../../Organisms/Sites/SitesDropdown'
import {AppState} from '../../../../Root.store'
import {switchView} from '../MaterialTest.action'
import {MaterialTestView} from '../MaterialTest.enum'
import {
  removeLookupFilter,
  removeSiteFilter,
  selectSiteFilter,
  setSiteFilter
} from '../MaterialTest.filters'
import {useTrackFilterEvents} from '../../../../TrackEvents/hubFilterEvents'
import {PageNames} from '../../../../common/constants'

interface SitesFilterType {
  filter: any
  setFilter: (props: any) => void
  removeFilter: () => void
  customers: Customer[]
  onLight?: boolean
}
const SitesFilter: React.FC<SitesFilterType> = ({
  filter = {},
  setFilter,
  removeFilter,
  onLight
}) => {
  const {t} = useTranslation()
  const {trackSiteFilterEvent} = useTrackFilterEvents()

  const handleChangeDropdown = (value: Site) => {
    const siteId = value ? value.siteId : null
    if (siteId) {
      setFilter({siteId})
    } else {
      removeFilter()
    }
    trackSiteFilterEvent(PageNames.RMC_TEST_CERTIFICATES, siteId)
  }

  return (
    <SitesDropdown
      label={t('order.orderDropdown.label')}
      noSelectionLabel={t('order.orderDropdown.noSelectionLabel')}
      onChange={handleChangeDropdown}
      selectedSiteId={filter.siteId}
      onLight={onLight}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  filter: selectSiteFilter(state),
  customers: selectCustomers(state).customers
})

const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => {
    dispatch(
      switchView({
        view: MaterialTestView.DEFAULT,
        clearPrevView: true
      })
    )
    dispatch(removeLookupFilter())
    dispatch(setSiteFilter(props))
  },
  removeFilter: () => dispatch(removeSiteFilter())
})
export default connect(mapStateToProps, mapDispatchToProps)(SitesFilter)
