import {Content} from '@hconnect/uikit'
import {withStyles, Theme, createStyles} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      height: '100%',
      flex: '1 0 auto',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    errorCode: {
      color: theme.palette.text.primary,
      fontSize: '250px',
      lineHeight: '250px'
    },
    errorMessage: {
      color: theme.palette.secondary.main
    },
    mailLink: {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.text.primary
      }
    }
  })

const Error404Page = ({classes}) => {
  const {t} = useTranslation()
  return (
    <Content>
      <div className={classes.container}>
        <div className={classes.errorCode}>404</div>
        <span className={classes.errorMessage}>
          <b>{t('error.error404.msg')}</b> {t('error.error404.action')}{' '}
          <a
            className={classes.mailLink}
            href={`mailto:${t('error.error404.email')}?subject=Mail%20from%20Hub%20Portal`}
          >
            {t('error.error404.email')}
          </a>
        </span>
      </div>
    </Content>
  )
}

export default withStyles(styles)(Error404Page)
