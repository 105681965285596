import {Page, Subheader, TitleNav} from '@hconnect/uikit'
import {Grid, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  header: {
    margin: `${theme.spacing(4)}px 0`
  }
}))

const NotYetAvailable: React.FC = () => {
  const {t} = useTranslation()

  const classes = useStyles()

  const goBack = () => window.history.back()

  return (
    <>
      <Subheader>
        <TitleNav title={t('back')} onClick={goBack} />
      </Subheader>
      <Page variant="sheet">
        <Grid container spacing={8}>
          <Grid item xs />
          <Grid item xs={8}>
            <Typography className={classes.header} variant="h1" component="h1">
              {t('missingContent.notYetAvailable')}
            </Typography>
            <Typography variant="body1" component="p">
              {t('missingContent.supportMessage')}{' '}
              <a
                href={`
                mailto:${t('missingContent.supportEmail')}?subject=Mail%20from%20Hub%20Portal
              `}
              >
                {t('missingContent.supportEmail')}
              </a>
              .
            </Typography>
          </Grid>
          <Grid item xs />
        </Grid>
      </Page>
    </>
  )
}

export default NotYetAvailable
