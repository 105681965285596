import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {MaterialTestPreview} from '../../../../Organisms/Materialtest'
import {useIconStyles} from '../../../Finance/Invoices/InvoiceDetail/components/styles'

import {DownloadMaterialTestsCard} from './DownloadMaterialTestsCard'

interface SelectedMaterialTestsAsideProps {
  materialTests: MaterialTestPreview[]
  t: TFunction
  close: () => void
  bulkDownload: (testIds: string[], includeTickets: boolean) => void
}

export const SelectedMaterialTestsAside: React.FC<SelectedMaterialTestsAsideProps> = ({
  materialTests,
  t,
  close,
  bulkDownload
}) => {
  const {classes} = useIconStyles()

  return (
    <Box>
      <CustomHeader title={t('materialTest.selectedMaterialTests')}>
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => close()}
          data-test-id="selected-material-tests-close"
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>

      <DownloadMaterialTestsCard materialTests={materialTests} bulkDownload={bulkDownload} t={t} />
    </Box>
  )
}
