export enum LookupSelectItem {
  ORDER = 'ORDER',
  TICKET = 'TICKET'
}

export type LookupMaterialTestItems = keyof typeof LookupSelectItem

export enum LookupEntityParam {
  ORDER = 'orderNumber',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  TICKET = 'orderNumber'
}

export enum MaterialTestView {
  DEFAULT = 'DEFAULT',
  LOOKUP = 'LOOKUP'
}

export type MaterialTestViews = keyof typeof MaterialTestView
