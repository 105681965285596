import {selectMaterialTest} from '../../../Organisms/Materialtest'
import {MaterialTestPreview} from '../../../Organisms/Materialtest/MaterialTest.types'
import {AppState} from '../../../Root.store'

import {MaterialTestViews} from './MaterialTest.enum'

export const selectView = (state: AppState) => state.materialTest.view
export const selectViewCurrent = (state: AppState) => selectView(state).currentView

export const selectViewMaterialTests = (state: AppState, view: MaterialTestViews) =>
  selectView(state)[view].map((materialTestId: string) => selectMaterialTest(state, materialTestId))

export const selectMaterialTestsByOrderNumber = (state: AppState, orderNumber: string) =>
  selectMaterialTest(state, orderNumber).items

export const selectGroupedTestsByOrderID = (state: AppState) => {
  const currentMaterialTests = selectViewMaterialTests(state, selectViewCurrent(state))
  const groupedMaterialTests: MaterialTestPreview[] = []
  Object.keys(currentMaterialTests).map((key: string) =>
    groupedMaterialTests.push(currentMaterialTests[key])
  )
  return groupedMaterialTests
}

export const selectError = (state: AppState) => state.materialTest.materialTests.error
