import {MaterialTestView, MaterialTestViews} from './MaterialTest.enum'

export const MATERIALTESTS_SWITCH_VIEW = 'MATERIALTESTS/SWITCH_VIEW'
export const MATERIALTESTS_CLEAR_VIEW = 'MATERIALTESTS/CLEAR_VIEW'

interface SwitchViewAction {
  type: typeof MATERIALTESTS_SWITCH_VIEW
  view: MaterialTestViews
  clearPrevView: boolean
}

interface ClearViewAction {
  type: typeof MATERIALTESTS_CLEAR_VIEW
}

export type MaterialTestPageActionTypes = SwitchViewAction | ClearViewAction

export interface MaterialTestPageState {
  currentView: MaterialTestViews
  [MaterialTestView.DEFAULT]: string[]
  [MaterialTestView.LOOKUP]: string[]
}
