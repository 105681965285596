import {Typography} from '@hconnect/uikit'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import {Box, Button, Card, Checkbox} from '@mui/material'
import {TFunction} from 'i18next'
import {useState} from 'react'

import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {MaterialTestPreview} from '../../../../Organisms/Materialtest'

interface DownloadMaterialTestsCardProps {
  materialTests: MaterialTestPreview[]
  bulkDownload: (testIds: string[], includeTickets: boolean) => void
  t: TFunction
}

export const DownloadMaterialTestsCard: React.FC<DownloadMaterialTestsCardProps> = ({
  materialTests,
  bulkDownload,
  t
}) => {
  const [materialTestsChecked, setMaterialTestsChecked] = useState<boolean>(true)
  const [ticketsChecked, setTicketsChecked] = useState<boolean>(false)

  const testsWithReports = materialTests
    .map((test) => test.items)
    .flat()
    .filter((test) => test.links?.some((link) => link.type === 'testResult'))

  const uniqueReports = new Set(testsWithReports.map((test) => test.reportNumber))

  const triggerBulkDownload = () => {
    const testIds = testsWithReports.map((test) => test.testId)
    bulkDownload(testIds, ticketsChecked)
  }

  const canDownload = (materialTestsChecked && uniqueReports.size > 0) || ticketsChecked

  return (
    <Card variant="outlined" sx={{padding: 2}} data-test-id="material-tests-download-options-card">
      <CustomHeader title={t('invoice.multiple.downloadOptions')} color="secondary" />

      <Box display="flex" alignItems="center">
        <Checkbox
          checked={materialTestsChecked}
          onChange={(_, checked) => setMaterialTestsChecked(checked)}
          data-test-id="download-material-tests-checkbox"
        />
        <Typography style={{flexGrow: 1}}>{t('materialTest.testReports')}</Typography>
        <Typography data-test-id="test-reports-count">{uniqueReports.size}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={ticketsChecked}
          onChange={(_, checked) => setTicketsChecked(checked)}
          data-test-id="download-tickets-checkbox"
        />
        <Typography style={{flexGrow: 1}}>{t('materialTest.relatedTickets')}</Typography>
      </Box>

      <Box mt={3} />

      <Button
        color="primary"
        variant="contained"
        startIcon={<DownloadOutlinedIcon />}
        disabled={!canDownload}
        onClick={triggerBulkDownload}
        data-test-id="multiple-downloads-button"
      >
        {t('invoice.multiple.downloadSelectedPDFs')}
      </Button>
    </Card>
  )
}
