import {DatePicker, oneMonth, oneYear} from '@hconnect/uikit'
import {Moment} from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useFeaturesState} from '../../../Organisms/Features'

interface Dates {
  startDate: Moment | null
  endDate: Moment | null
}
interface DateFilterType {
  filter: Dates
  onDateChange: (filter: Dates) => void
}
export const DateFilter: React.FC<DateFilterType> = ({filter, onDateChange, ...props}) => {
  const {getFeature} = useFeaturesState()
  const showMax31DateRange = getFeature('MaxDayRange31')
  const {startDate, endDate} = filter
  const {t} = useTranslation()
  const getDatePickerQuickSelectionItems = (locale: string) => [
    {
      key: 'one-year',
      title: t('certificate.datePicker.oneYear'),
      type: 'range',
      dates: oneYear(locale)
    },
    {
      key: 'one-month',
      title: t('certificate.datePicker.oneMonth'),
      type: 'singleDate',
      dates: oneMonth(locale)
    }
  ]

  return (
    <DatePicker
      data-test-id="milltests-filter-datepicker"
      startDate={startDate}
      endDate={endDate}
      hideClearButton
      {...(showMax31DateRange && {
        maxDayRange: 31,
        maxDayRangeInfoMessage: t('datePicker.maxDayRange')
      })}
      handleDateChange={onDateChange}
      getQuickSelectionItems={getDatePickerQuickSelectionItems}
      {...props}
    />
  )
}
