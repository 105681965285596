import {useQuery} from '@tanstack/react-query'

import {api} from '../../../../App.global'

export const useFetchDelivery = (params) => {
  return useQuery(['deliveries', params], async () => {
    const res = await api.get('/deliveries', {params})
    return res?.data?.[0] ?? null
  })
}
