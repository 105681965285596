import {User} from '@hconnect/apiclient'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Link, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {uniq} from 'lodash'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import CheckCircle from '../../../../Assets/certificateIcons/checked-circle.svg'
import Schedule from '../../../../Assets/certificateIcons/pending.svg'
import {Optional} from '../../../../common/types'
import {MaterialTest, MaterialTestPreview} from '../../../../Organisms/Materialtest'
import {AppState} from '../../../../Root.store'
import {Delivery} from '../../../Finance/Invoices/Delivery.types'
import {ValueDiv} from '../../../Order/components'
import {
  downloadDeleveryTicket,
  downloadMaterialTest,
  getDeliveryDocumentLink,
  getTestDocumentLink
} from '../MaterialTest.utils'

export const MaterialTestStatus: React.FC<{test: MaterialTestPreview; t: TFunction}> = ({
  test,
  t
}) => {
  return (
    <Typography>
      {test.available ? (
        <Box display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
          <img src={CheckCircle} alt="" />
          <Box p={0.25} />
          {t('materialTest.testReportStatus.available')}
        </Box>
      ) : (
        <Box display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center">
          <img src={Schedule} alt="" />
          <Box p={0.25} />
          {t('materialTest.testReportStatus.pending')}
        </Box>
      )}
    </Typography>
  )
}

interface TestAvailabilityTemplateProps {
  test: MaterialTest
  t: TFunction
}

export const TestAvailabilityTemplate: React.FC<TestAvailabilityTemplateProps> = ({test, t}) => {
  const {testAge} = (test?.specimens && test?.specimens[0]) || {}
  const sortedSpecimens = (testAge as number[]).sort((a, b) => a - b)
  const uniqueTestAge: number[] = uniq(sortedSpecimens)

  if (!sortedSpecimens) {
    return <ValueDiv>-</ValueDiv>
  }
  if (uniqueTestAge.length === 1 && uniqueTestAge[0] === 0) {
    return <ValueDiv>{t('materialTest.testReportStatus.atSamplingDay')}</ValueDiv>
  }
  if (uniqueTestAge.length === 1 && uniqueTestAge[0] !== 0) {
    return <ValueDiv>{`${uniqueTestAge[0]}`}</ValueDiv>
  }

  return (
    <Box display="flex" flexDirection="row">
      {uniqueTestAge?.map((age: number, index: number, uniqueTestAge) => {
        if (uniqueTestAge[index + 2]) {
          return <ValueDiv key={index}>{`${age},\u00A0`}</ValueDiv>
        }
        if (uniqueTestAge[index + 1]) {
          return <ValueDiv key={index}>{`${age}\u00A0`}</ValueDiv>
        }
        if (!uniqueTestAge[index + 1]) {
          return (
            <ValueDiv key={index} color="textPrimary" variant="body2">{`${t(
              'materialTest.expand.andForLastTestAge'
            )} ${age}`}</ValueDiv>
          )
        }
      })}
    </Box>
  )
}

interface TestNumberProps {
  test: MaterialTest
  t: TFunction
  startDate: string
  endDate: string
}
export const TestNumber: React.FC<TestNumberProps> = ({test, t, startDate, endDate}) => {
  const {reportNumber} = test
  const testPdf = getTestDocumentLink(test)
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const dispatch = useDispatch()

  if (!testPdf) return <ValueDiv>{reportNumber}</ValueDiv>
  return (
    <Link
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        downloadMaterialTest(test, user, startDate, endDate, t, dispatch)
      }}
      href="#"
    >
      <Box display="flex" gap={1}>
        <PictureAsPdfIcon
          data-test-id={`pdficon-${reportNumber}`}
          fontSize="small"
          color="primary"
        />
        {reportNumber}
      </Box>
    </Link>
  )
}

interface DeliveryNumberProps {
  test: MaterialTest
  t: TFunction
  startDate: string
  endDate: string
  deliveryData?: Optional<Delivery>
}
export const DeliveryNumber: React.FC<DeliveryNumberProps> = ({
  test,
  t,
  startDate,
  endDate,
  deliveryData
}) => {
  const {deliveryNumber} = test
  const deliveryPdf = getDeliveryDocumentLink(deliveryData)
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const dispatch = useDispatch()

  if (!deliveryPdf) return <ValueDiv>{deliveryNumber}</ValueDiv>
  return (
    <Link
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        downloadDeleveryTicket(test, deliveryData, user, startDate, endDate, t, dispatch)
      }}
      href="#"
    >
      <Box display="flex" gap={1}>
        <PictureAsPdfIcon
          data-test-id={`pdficon-${deliveryNumber}`}
          fontSize="small"
          color="primary"
        />
        {deliveryNumber}
      </Box>
    </Link>
  )
}
